import React from 'react'

const SalesDashboard = () => {
    return (
        <div>
            SalesDashboard
        </div>
    )
}

export default SalesDashboard
